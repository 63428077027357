<template>
  <div>
    <v-card flat>
      <v-card-text class="my-0 py-0">
        <v-row align="center" justify="end">
          <v-col cols="12" sm="6" md="4">
            <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search"
              id="search">
              <template slot="prepend">
                <el-button @click="getStockFiles">
                  <v-icon>refresh</v-icon>
                </el-button>
              </template>
            </el-input>
          </v-col>
        </v-row>
        <v-data-table dense class="mt-3" disable-pagination fixed-header hide-default-footer :loading="loading"
          :items="files.data" :headers="headers" height="65vh" :calculate-widths="true" style="cursor: pointer"
           id="table">
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>            
                  <template v-slot:activator="{ on }">
                    <v-btn  icon small @click="viewItem(item)">
                      <v-icon v-on="on" small>launch</v-icon></v-btn>

                  </template>
                  <span>Open Document</span>
                </v-tooltip>
          <v-tooltip top
                  v-if="item.bookingContainer && item.bookingContainer.id && item.bookingContainer.shipmentId">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" icon small @click="openContainer(item.bookingContainer)">
                      <v-icon v-on="on" small>visibility</v-icon></v-btn>

                  </template>
                  <span>View linked container</span>
                </v-tooltip>
                </template>
          <template v-slot:[`item.containerNo`]="{ item }">
            <div v-if="item.isBreakbulk">
              <v-chip small>Breakbulk</v-chip>
            </div>
            <div v-else>
              <div v-if="item.stockFileContainers.length > 0">
                <v-chip small>{{ item.stockFileContainers.length }} Container(s)</v-chip>
                <!-- <span v-if="item.stockFileContainers.length > 3"> + {{ item.stockFileContainers.length - 3 }} others</span> -->
              </div>
              <div v-else>
               
                <v-tooltip top v-if="item.bookingContainerId">
                  <template v-slot:activator="{ on }">
                    <v-icon color="blue" v-on="on" small>verified</v-icon>
                  </template>
                  <span>Linked to container</span>
                </v-tooltip>
                {{ item.containerNo }}
              </div>
            </div>


          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small outlined style="border:none"><v-icon small left  :color="getStatusColor(item.status)">fiber_manual_record</v-icon> {{ item.status }}</v-chip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.processed`]="{ item }">
            <v-icon v-if="item.processed" color="success">check_circle_outline</v-icon>
            <v-icon v-else color="red">cancel</v-icon>
          </template>
          <template v-slot:[`item.gradeClassCode`]="{ item }">
            <template v-if="item.gradeClassCode.includes('1')">
              {{ 1 }}
            </template>
            <template v-else-if="item.gradeClassCode.includes('2')">
              {{ 2 }}
            </template>
            <template v-if="item.gradeClassCode.includes('P')">
              {{ 'P' }}
            </template>
          </template>
        </v-data-table>
        <v-col cols="12" class="text-center">
          <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
            :page-size.sync="params.limit" :page-sizes="[16, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'
              " :total="files.total">
          </el-pagination>
        </v-col>
      </v-card-text>
    </v-card>

    <v-dialog v-model="stockModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="stockItem && stockItem.id">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ stockItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn text @click="stockModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table dense :headers="contentHeaders" :items="stockItem.pallets"></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewModal" width="1200px" fullscreen persistent>
      <v-card v-if="stockItem && stockItem.id">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 14px">
            <v-chip class="mr-2" :color="getStatusColor(stockItem.status)" small outlined><v-icon small left>{{
              getStatusIcon(stockItem.status)
            }}</v-icon> {{ stockItem.status }}</v-chip>
            {{ stockItem.name }}
            <v-chip class="ml-3" v-if="stockItem.isBreakbulk">Breakbulk</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="stockItem.url">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="downloadItem(stockItem)"><v-icon>download</v-icon></v-btn>
            </template>
            <span>Download Original Document</span>
          </v-tooltip>
          <v-btn text @click="viewModal = false, stockItem = {}, selectedContainer = null">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-list dense>
                        <v-list-item v-if="stockItem.uploadedBy">
                          <v-list-item-action>
                            <v-avatar :color="stockItem.uploadedBy.avatar ? 'white' : 'secondary'" size="40">
                              <v-img v-if="stockItem.uploadedBy.avatar" referrerpolicy="no-referrer"
                                :src="stockItem.uploadedBy.avatar" contain></v-img>
                              <h2 v-else style="color: white; font-weight: normal">{{
                                stockItem.uploadedBy.firstname.charAt(0) }}</h2>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ stockItem.uploadedBy.firstname }} {{ stockItem.uploadedBy.surname }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ formatDate(stockItem.createdAt) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Uploaded
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                          <v-list-item-action>
                            <v-icon color="grey">
                              schedule
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ formatDate(stockItem.createdAt) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Uploaded Time
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="stockItem.processed">
                          <v-list-item-action>
                            <v-icon :color="getStatusColor(stockItem.status)">{{ getStatusIcon(stockItem.status)
                            }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ stockItem.statusDescription }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="stockItem.type">
                          <v-list-item-action>
                            <v-icon color="grey">category</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ stockItem.type }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              File Classification
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="stockItem.processed">
                          <v-list-item-action>
                            <v-icon color="grey">
                              schedule
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ formatDate(stockItem.processTime) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Processed Time
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="stockItem.linkedOrganisation">
                          <v-list-item-action>
                            <v-icon color="grey">
                              business
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ stockItem.linkedOrganisation.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Linked Organisation
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-icon color="grey">
                              article
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="stockItem.stockFileMapping">
                              {{ stockItem.stockFileMapping.alias }}
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              No mapping
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              File Template
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row justify="center">
                              <v-tooltip top v-if="fileTemplates.length > 0">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon small v-on="on" @click="fileTemplateModal = true"><v-icon
                                      small>edit</v-icon></v-btn>
                                </template>
                                <span>Change File Template</span>
                              </v-tooltip>
                              <v-tooltip top v-if="stockItem.stockFileMapping">
                                <template v-slot:activator="{ on }">
                                  <v-btn icon small v-on="on" @click="reprocessFile(stockItem)"><v-icon small
                                      color="blue">refresh</v-icon></v-btn>
                                </template>
                                <span>Reprocess file</span>
                              </v-tooltip>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item  v-if="stockItem.stockFileMapping"  @click="!stockItem.stockFileCreated?createStock():null">
                          <v-list-item-action>
                            <v-icon color="grey">
                              redo
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="!stockItem.stockFileCreated">
                              Process to stock
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              Already Processed to Stock
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              This action creates stock entries
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                          <v-progress-circular size="20" indeterminate color="blue" v-if="creatingStock"></v-progress-circular>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list dense v-if="stockItem.stockFileContainers.length > 0">
                          <v-subheader style="font-size: 16px">Containers <v-spacer></v-spacer> <v-chip>{{
                            stockItem.stockFileContainers.length }}</v-chip></v-subheader>
                          <v-divider></v-divider>
                          <v-list dense style="max-height: 58vh; overflow-y: auto">
                            <v-list-item v-for="container in stockItem.stockFileContainers" :key="container.id"
                              :style="{ 'border-left': selectedContainer && selectedContainer.id == container.id ? '3px solid var(--v-primary-base)' : '3px solid transparent' }"
                              @click="selectedContainer = container">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ container.containerNo }}
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 12px">
                                  Seal No: {{ container.sealNo }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle style="font-size: 12px">
                                  Reference: {{ container.containerRef }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-tooltip top v-if="container.bookingContainerId">
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="blue" v-on="on" small>verified</v-icon>
                                  </template>
                                  <span>Linked to container</span>
                                </v-tooltip>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-list>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <div v-if="stockItem.stockFileContainers.length > 0">
                        <div v-if="selectedContainer">
                          <v-card flat>
                            <v-card-text class="my-0 py-0">
                              <v-row>
                                <v-col cols="12" sm="3">
                                  <v-list-item>
                                    <v-list-item-action>
                                      <v-icon color="grey">
                                        tag
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ selectedContainer.containerNo }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle v-if="selectedContainer.sealNo">
                                        Seal No: {{  selectedContainer.sealNo }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        Container Number
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                               
                                <v-col cols="12" sm="3">
                                  <v-list-item>
                                    <v-list-item-action>
                                      <v-icon color="grey">
                                        schedule
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ selectedContainer.stuffDate ?? '-' }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Stuff Date
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <v-list-item v-if="selectedContainer.bookingContainer">
                                    <v-list-item-action>
                                      <v-icon color="grey">
                                        link
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ selectedContainer.bookingContainer.ctoNo }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Linked CTO
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-row justify="center">
                                        <v-btn icon color="secondary" @click="viewCTOLink()" small>
                                          <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <v-btn small v-if="selectedContainer.bookingContainer.shipmentId" icon
                                          @click="openContainer(selectedContainer.bookingContainer)">
                                          <v-icon small>launch</v-icon>
                                        </v-btn>
                                      </v-row>

                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item v-else @click="viewCTOLink()">
                                    <v-list-item-action>
                                      <v-icon color="blue">
                                        add_link
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Link to CTO
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" v-if="selectedContainer.bookingContainer">
                                  <v-list-item v-if="selectedContainer.bookingContainer" @click="updateDataSource(selectedContainer.bookingContainer, selectedContainer, 'stockFileContainer')">
                                    <v-list-item-action>
                                      <v-icon color="grey">
                                        bar_chart
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Set as Active Data Source
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-progress-circular v-if="savingDataSource" color="primary" indeterminate></v-progress-circular>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                          <v-data-table :loading="loadingContainerPallets" dense fixed-header height="75vh"
                            disable-pagination hide-default-footer :headers="contentHeaders"
                            :items="selectedContainer.pallets"></v-data-table>
                        </div>

                      </div>
                      <div v-else>
                        <v-card flat>
                          <v-card-text class="my-0 py-0">
                            <v-row>
                              <v-col cols="12" sm="3">
                                <v-list-item>
                                  <v-list-item-action>
                                    <v-icon color="grey">
                                      tag
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ stockItem.containerNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ stockItem.sealNo }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      Container Number
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-list-item>
                                  <v-list-item-action>
                                    <v-icon color="grey">
                                      schedule
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ stockItem.stuffDate ?? '-' }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Stuff Date
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-list-item v-if="stockItem.bookingContainer">
                                  <v-list-item-action>
                                    <v-icon color="grey">
                                      link
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ stockItem.bookingContainer.ctoNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      Linked CTO
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-row justify="center">
                                      <v-btn icon color="secondary" @click="viewCTOLink()" small>
                                        <v-icon small>edit</v-icon>
                                      </v-btn>
                                      <v-btn v-if="stockItem.bookingContainer.shipmentId" icon
                                        @click="openContainer(stockItem.bookingContainer)" small>
                                        <v-icon small>launch</v-icon>
                                      </v-btn>
                                    </v-row>

                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-else @click="viewCTOLink()">
                                  <v-list-item-action>
                                    <v-icon color="blue">
                                      add_link
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Link to CTO
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              
                              </v-col>
                              <v-col cols="12" sm="3" v-if="stockItem.bookingContainer">
                                  <v-list-item v-if="stockItem.bookingContainer" @click="updateDataSource(stockItem.bookingContainer, stockItem, 'stockFile')">
                                    <v-list-item-action>
                                      <v-icon color="grey">
                                        bar_chart
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Set as Active Data Source
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-progress-circular v-if="savingDataSource" color="primary" indeterminate></v-progress-circular>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-data-table v-if="stockItem.pallets" dense fixed-header height="75vh" disable-pagination
                          hide-default-footer :headers="contentHeaders" :items="stockItem.pallets"></v-data-table>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fileTemplateModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="settingTemplate">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Select File Template
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="fileTemplateModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear v-if="fileTemplateLoading" color="primary" indeterminate></v-progress-linear>
          <v-list dense style="height: 80vh;overflow-y: auto" v-if="!fileTemplateLoading">
            <v-list-item v-for="template in fileTemplates" :key="template.id" @click="setFileTemplate(template)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ template.alias }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ template.type }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="template.linkedOrganisation">
                  <v-icon small color="grey" class="mr-1">business</v-icon>
                  {{ template.linkedOrganisation.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ctoLinkModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon class="mr-2">link</v-icon> Link CTO
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="ctoLinkModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field autofocus prepend-inner-icon="search" v-model="searchCTO" outlined dense clearable></v-text-field>
          <v-progress-linear v-if="loadingCTO" color="primary" indeterminate></v-progress-linear>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="!loadingCTO && ctoData.length == 0 && searchCTO">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No data found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="cto in ctoData" :key="cto.id" @click="linkContainer(cto)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ cto.ctoNo }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 12px">
                  Container No: {{ cto.containerNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 12px">
                  Seal No: {{ cto.sealNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 12px" v-if="cto.customerContainerRef">
                  Reference: {{ cto.customerContainerRef }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 12px" v-if="cto.shipmentFile">
                  <v-icon small color="grey" class="mr-1">article</v-icon> File No: {{ cto.shipmentFile.fileNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="containerManagementModal" fullscreen>
      <ContainerManagement v-if="selectedContainer"
        :container="selectedContainer"
        :shipment="selectedContainer.shipment"
        @close="containerManagementModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import ContainerManagement from '../Documentation/ContainerComponents/ContainerManagement.vue';
import dateFormat from "dateformat";
export default {
  components: {
    ContainerManagement
  },
  data: () => ({
    containerManagementModal: false,
    creatingStock: false,
    ctoData: [],
    ctoLinkModal: false,
    ctoExistingQuery: null,
    ctoSearchTimeout: null,
    files: {
      total: 0,
      data: []
    },
    fileTemplates: [],
    fileTemplateLoading: false,
    fileTemplateModal: false,
    headers: [
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      // {
      //   text: "Processed",
      //   value: "processed",
      //   align: "center",
      // },
      {
        text: "Type",
        value: "type",
        align: "center",
      },
      {
        text: "Source",
        value: "documentSource",
        align: "center",
      },
      // {
      //   text: "Linked Organisation",
      //   value: "linkedOrganisation.name",
      //   align: "center",
      // },
      {
        text: "Created",
        value: "createdAt",
        align: "center"
      },
      {
        text: "Location Code",
        value: "locationCode",
        align: "center",
      },
      {
        text: "Vessel",
        value: "vesselName",
        align: "left",
      },
      {
        text: "Voyage",
        value: "voyageNo",
        align: "center",
      },
      {
        text: "Cargo Item(s)",
        value: "containerNo",
        align: "center",
      },
      // {
      //   text: "Seal No",
      //   value: "sealNo",
      //   align: "center",
      // },
      // {
      //   text: "Cartons",
      //   value: "cartonQuantity",
      //   align: "center",
      // },
      {
        text: "Pallets",
        value: "palletQuantity",
        align: "center",
      }
    ],
    contentHeaders: [
      {
        text: "SSCC",
        value: "sscc",
        align: "center",
      },
      {
        text: "Sequence No",
        value: "sequenceNumber",
        align: "center",
      },
      {
        text: "Deck Position",
        value: "deckPosition",
        align: "center",
      },
      {
        text: "Container no",
        value: "containerNo",
        align: "center"
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        align: "center",
      },

      {
        text: "Production Area",
        value: "productionArea",
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        align: "center",
      },
      {
        text: "No. of Cartons",
        value: "noCartons",
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight",
        value: "nettWeight",
        align: "right",
      },
      {
        text: "Gross Weight",
        value: "grossWeight",
        align: "right",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        align: "center",
      },
      {
        text: "Inspection Date",
        value: "inspectionDate",
        width: "100px",
        align: "center",
      },

      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        align: "center",
      },
      {
        text: "Inspector",
        value: "inspector",
        align: "center",
      },
    ],
    key: 2000,
    loading: false,
    loadingContainerPallets: false,
    loadingCTO: false,
    page: 1,
    params: {
      search: null,
      limit: 16,
      offset: 0,
      filter: {
        // type: 'PO'
      },
    },
    searchCTO: null,
    settingTemplate: false,
    selectedContainer: null,
    stockModal: false,
    stockItem: {},
    savingDataSource: false,
    viewModal: false,
  }),
  watch: {
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getStockFiles();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getStockFiles();
      },
    },
    "params.search": {
      async handler() {
        await this.getStockFiles();
      },
    },
    async selectedContainer(val) {
      if (val && val.id) {
        this.loadingContainerPallets = true
        if (val.contents) {
          // val.pallets =[]
          let pallets = await axios.get(val.contents)
          this.selectedContainer.pallets = pallets.data
          this.key++
        } else {
          val.pallets = []
        }
        this.loadingContainerPallets = false
      }
    },
    searchCTO(val) {
      this.searchCTOData()
    }
  },
  async created() {
    this.getFileMappings()
  },
  methods: {
    async createStock(){
      try {
        this.creatingStock = true
        let result = await this.$API.createStockFromFile(this.stockItem.id)
        console.log(result)
        this.stockItem.stockFileCreated = true
        this.creatingStock = false
        this.$message.success("Stock entries created successfully")
      } catch(e){
        console.log(e)
        this.creatingStock = false
        this.$message.error("An error ocurred when creating the entries - please contact system admin.")
      }
    },
    async downloadItem(item) {
      let result = await axios.get(item.url)
      var hiddenElement = document.createElement("a");
      hiddenElement.href = item.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = item.name;
      hiddenElement.click();
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    getStatusColor(status) {
      switch (status) {
        case 'Processed':
          return 'success'
        case 'Processing':
          return 'blue'
        case 'Reprocessing':
          return 'blue'
        case 'Pending':
          return 'warning'
        case 'Error':
          return 'red'
        default:
          return 'grey'
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case 'Processed':
          return 'check_circle_outline'
        case 'Processing':
          return 'autorenew'
        case 'Reprocessing':
          return 'autorenew'
        case 'Pending':
          return 'pending'
        case 'Error':
          return 'cancel'
        default:
          return 'info'
      }
    },
    async getFileMappings() {
      this.fileTemplateLoading = true
      this.fileTemplates = await this.$API.getFileMappings()
      this.fileTemplateLoading = false
    },
    async getStockFiles() {
      this.loading = true
      this.files = await this.$API.getStockFiles({
        params: this.params
      })
      this.loading = false

    },
    async linkContainer(container) {
      this.loadingCTO = true
      if (this.selectedContainer && this.stockItem.stockFileContainers.length > 0) {
        this.selectedContainer.bookingContainerId = container.id
        this.selectedContainer.bookingContainer = container
        await this.$API.updateStockFileContainer({
          id: this.selectedContainer.id,
          bookingContainerId: container.id
        })
        this.$message.success('Successfully updated document link!')
      } else {
        this.stockItem.bookingContainerId = container.id
        this.stockItem.bookingContainer = container
        await this.$API.updateStockFile({
          id: this.stockItem.id,
          bookingContainerId: container.id
        })
        this.$message.success('Successfully updated document link!')
      }

      this.ctoLinkModal = false
      this.loadingCTO = false
    },
    async openContainer(container) {
      let detail = await this.$API.getContainer(container.id)
      detail.shipment = container.shipment
      // this.$router.push({ path: "/container/" + container.id + "/" + container.shipmentId });
      this.selectedContainer = detail
      this.containerManagementModal = true
    },

    async searchCTOData() {
      if (this.ctoSearchTimeout) {
        clearTimeout(this.ctoSearchTimeout);
      }
      if (this.loadingCTO && this.ctoExistingQuery) {
        this.ctoExistingQuery.abort()
      }
      this.loadingCTO = true;
      this.ctoSearchTimeout = setTimeout(async () => {
        this.ctoExistingQuery = new AbortController();
        const signal = this.ctoExistingQuery.signal
        this.ctoData = await this.$API.searchCTOLink({
          params: {
            search: this.searchCTO,
            vesselId: this.stockItem.vesselId,
            vesselName: this.stockItem.vesselName,
            isBreakBulk: this.stockItem.isBreakBulk
          },
          signal
        });
        this.loadingCTO = false;
      }, 750);
    },
    async setFileTemplate(template) {
      this.settingTemplate = true
      this.stockItem.stockFileMapping = template
      this.stockItem.stockFileMappingId = template.id
      this.stockItem.status = 'Reprocessing'
      this.stockItem.statusDescription = 'File Template changed'
      await this.$API.updateStockFile(this.stockItem)
      this.$message.success('File template updated and is now reprocessing')
      this.fileTemplateModal = false
      this.settingTemplate = false
    },

    async reprocessFile(file) {
      file.status = 'Reprocessing'
      file.statusDescription = 'File marked for reprocessing'
      file.processed = false
      await this.$API.updateStockFile(file)
      this.$message.success('File reprocessing started')
    },
    updateDataSource(container, item, type){
      console.log(container, item, type)
      this.$confirm(`Are you sure you want to update the data source ?`, "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    try {
                        this.savingDataSource = true
                        let obj = [{
                          source: item.type,
                          type: item.type,
                          id: item.id,
                          sourceType: type,
                          contents: item.pallets
                        }]
                        // let active = this.availableSources.filter(x => x.active)
                        await this.$API.updateDocumentSource({
                            bookingContainerId: container.id,
                            source: obj
                        })
                        this.savingDataSource = false
                        this.$message.success('Successfully updated data source!')
                    } catch (e) {
                        this.$message({
                            type: "error",
                            message: "There was an error changing the document source.",
                        });
                        this.savingDataSource = false
                    }
                })
    },
    viewCTOLink() {
      this.searchCTO = null
      this.ctoData = []
      this.ctoLinkModal = true
    },
    async viewItem(item) {
      this.stockItem = item
      if (item.contents) {
        let pallets = await axios.get(item.contents)
        this.stockItem.pallets = pallets.data
      }
      if (this.stockItem.stockFileContainers.length > 0) {
        this.stockItem.stockFileContainers.forEach(x => {
          x.pallets = []
        })
        this.selectedContainer = this.stockItem.stockFileContainers[0]
      }
      this.viewModal = true
    }
  }

}
</script>